import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { cancelarProcesoEspecial, fetchProyectos, fetchProyectosByUsuario, removeProyecto, resellarProyecto } from '../../reducers/proyecto_slice';
import FormProyecto from './FormProyecto';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import ActionButton from '../UI/Buttons/ActionButton';
import { formatNumber, apellidosNombreFullPersona, formatMayusculaCadaPalabra } from '../../util/customFormatter';
import CustomPrintModalH from '../UI/CustomPrintModalH';
import VerProyectoModal from './VerProyectoModal';
import { formatFecha, formatFechaHora, getEstadoProyecto, getNombreProcesoEspecialVisado, numeroALetras } from '../../util/valueCalculator';
import { InputGroup } from 'react-bootstrap';
import ImprimirRecibo, { fechaHoraBdAjustada } from '../recibo/ImprimirRecibo';
import { fetchReciboById } from '../../reducers/recibo_slice';
import CustomBadge from './CustomBadge';
import { fetchTiposProyecto } from '../../reducers/tipoProyecto_slice';
import moment from 'moment-timezone';
const ListProyectos = () => {
  const dispatch = useDispatch();
  const proyectoState = useSelector(state => state.proyecto);
  const proyectos = proyectoState?.proyectos || [] ;
  const [showModal, setShowModal] = useState({open:false, tipo:""});
  const [showModalVer, setShowModalVer] = useState(false);
  const [currentProyecto, setCurrentProyecto] = useState(null);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [printContent, setPrintContent] = useState({ head: [], body: [] });
  const { rolActivo, nombre, id:idUser} = useSelector(state => state.sesion);
  const [sortConfig, setSortConfig] = useState({ key1: "", key2: '', key3: null,  direction: 'asc' });
  const [searchTerm, setSearchTerm] = useState({numVisado:'', 
                                                estado:'activos', 
                                                proceso:'', 
                                                tipoProyectoId:"",
                                                textoFiltro:"", 
                                                filtroSelected:""});
  const [filters, setFilters] =useState({fechaCreacion:"", fechaEdicion:""})
  const [showModalRecibo, setShowModalRecibo] = useState(false); 
  const [reciboData, setReciboData] = useState(null);
  const [proyectoDesplegado, setProyectoDesplegado] = useState(null);
  const navigate = useNavigate();
  const [headerColor, setHeaderColor] = useState("#7b2829");
   const tiposProyectos = useSelector(state => state.tipoProyecto.tipos || []);

  useEffect(() => {
    dispatch(fetchTiposProyecto());
  }, [dispatch]);
  console.log("Tipos proye",tiposProyectos)

  useEffect(() => {
    if(rolActivo){
      if(rolActivo?.alias === "afiliado") dispatch(fetchProyectosByUsuario()) 
    else {dispatch(fetchProyectos()) }
  }
  }, [dispatch,rolActivo]);

  const handleDelete = id => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "¡No podrás revertir esto!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#464646',
      confirmButtonText: 'Sí, eliminarlo!',
       cancelButtonText: 'Cancelar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(removeProyecto(id)).unwrap().then(() => {
          Swal.fire('¡Eliminado!', 'El proyecto de construcción ha sido eliminado.', 'success');
        }).catch((error) => {
          Swal.fire('Error', error.error || 'Ocurrió un error al eliminar el proyecto de construcción', 'error');
        });
      }
    });
  };
  const handleCancelarProcesoEspecial = (id, proceso) => {
    Swal.fire({
      title: `Confirmar Cancelación de ${proceso}`,
      text: "Se retrocederá al último antecedente.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#464646',
      confirmButtonText: 'Sí, confirmo cancelación',
       cancelButtonText: 'Cerrar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(cancelarProcesoEspecial(id)).unwrap().then(() => {
          Swal.fire('¡Eliminado!', 'El proyecto de construcción ha sido eliminado.', 'success');
        }).catch((error) => {
          Swal.fire('Error', error.error || `Ocurrió un error con la cancelación de ${proceso}`, 'error');
        });
      }
    });
  };
 
  const handleSort = (key1, key2, key3=null) => {
    if (sortConfig.direction === 'desc') {
      setSortConfig({ key3:null, key1:null, key2: null, direction: 'asc' });
      return;
    }
    let direction = 'asc';
    if (sortConfig.key3 === key3 && sortConfig.key1 === key1 && sortConfig.key2 === key2 && sortConfig.direction === 'asc') {
      direction = 'desc';
    } else if (sortConfig.key3 === key3 && sortConfig.key1 === key1 && sortConfig.key2 === key2 && sortConfig.direction === 'desc') {
      direction = null; // Si ya estaba descendente, lo dejamos sin ordenar
    }
    setSortConfig({key3, key1, key2, direction });
  };

  const handleEdit = proyecto => {
    setCurrentProyecto(proyecto);
    setShowModal({open:true, tipo:"editar"});
  };

  const handleViewRecibo = async (proyecto) => {
    try {
        const recibo = proyecto.recibos[0]? await dispatch(fetchReciboById(proyecto.recibos[0].id)).unwrap() : null
        const totalLiteral = numeroALetras(recibo.monto);
        setCurrentProyecto(proyecto)
        setReciboData(recibo? {
            recibo,
            reciboDetalles: recibo.reciboDets,
            totalLiteral
        }:null);
        setShowModalRecibo(true);
    } catch (error) {
        Swal.fire('Error', 'No se pudo obtener el recibo', 'error');
    }
};
const togglePropietarios = (id) => {
  setProyectoDesplegado((prev) => (prev === id ? null : id));
};
  const handleVer= proyecto => {
    const currentProyecto = proyecto
    setCurrentProyecto(currentProyecto);
    if(currentProyecto?.estado === "borrador"){
      setHeaderColor("#006bb2")
      }else if(currentProyecto?.estado === "revision"){
          setHeaderColor("#b89209")
      }else if(currentProyecto?.estado === "aprobado"){
        setHeaderColor("#aa4f09")
    }else if(currentProyecto?.estado === "visado"){
      setHeaderColor("#0a7d3f")
  }else if(currentProyecto?.estado === "anulado"){
    setHeaderColor("#888b8d")
}
setShowModalVer(true);
  };


  const handleCloseVer = () => {
    setShowModalVer(false);
      setTimeout(() => {
    setCurrentProyecto(null);
  }, 300); 
  };

  const handleCreate = () => {
    setCurrentProyecto(null);
    setShowModal({open:true, tipo:"crear"});
  };
  const handleDuplicar = (proyecto) => {
    const {id, recibos, num_visado, createdAt, updatedAt,
      id_gerente, gerente, id_usuario_creador, usuario,
      fecha_registro, fecha_aprobacion, observacion, comentario1,
      id_proyecto_resellado,
      foto_plano, usuarioAnulador, id_usuario_anulador, ...proyectoToDuplciate} = proyecto
    setCurrentProyecto({...proyectoToDuplciate,
      estado:"borrador"
    });
    setShowModal({open:true, tipo:"duplicar"});
  };

  const handleResellar = async(proyecto) => {
    let mensajeAlerta = `
    <strong>RESELLAR</strong> proyecto <strong>N° ${proyecto.num_visado}.</strong>
    <br> <br>
    <small>Se creará un nuevo proyecto con el mismo N° de visado que tendrá que volver a ser <strong>revisado, aprobado y pagado</strong>. 
    <br> <br>
    También <strong>se anulará</strong> éste ya visado.</small>
  `;
  
    const result = await Swal.fire({
        title: '¿Estás seguro?',
        html: mensajeAlerta, // Usa HTML aq
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#464646',
        confirmButtonText: 'Sí, continuar',
        cancelButtonText: 'Cancelar'
        })
      if (result.isConfirmed) {
        console.log("Resellando", proyecto)
        dispatch(resellarProyecto({id:proyecto.id})).then((response)=>{
          console.log("REsponse Resellando", response)
          if (response.error || response.payload.error || response.payload.messages) {
              Swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text:  response.payload.error || response.payload.messages[0] || 'Error al resellar el proyecto. Intente de nuevo más tarde.',
              });
          } else {
            Swal.fire(`Resellado iniciado`, `Se ha iniciado el resellado del proyecto N°${proyecto.num_visado}`, 'success');   
                      
          }
        })
      }
  };
  
  const handleTransferir = (proyecto)=>{
   
    const {createdAt, updatedAt, gerente, 
      num_visado,
      usuario, id, usuarioAnulador, proyectoPrevio, recibos, ...proyectoTransferir} = proyecto
    setCurrentProyecto({...proyectoTransferir,
          estado:"borrador",
          foto_plano:null,
          fecha_registro:null,
          id_gerente:"",
          id_usuario_creador:"",
          fecha_aprobacion:null,
          id_proyecto_resellado:id,
          proyectoPrevio:proyecto

    });
    setShowModal({open:true, tipo:"transferir"});
  }



  const handleClose = () => {
    setShowModal({open:false, tipo:""});
    setCurrentProyecto(null);
  };

  const handleBack = () => {
    navigate(-1);
  };
  const handleSearch = (event) => {
    const {name, value} =event.target
    setSearchTerm(prev => ({...prev, [name]:value}));
  };

  const handleExport = () => {
    const contentArrays = getPreviewContent()
    // Combinar las cabeceras y los datos
    const exportData = [...contentArrays.head, ...contentArrays.body];
    // Crear la hoja de trabajo con las cabeceras personalizadas
    const worksheet = XLSX.utils.aoa_to_sheet(exportData); // Utiliza aoa_to_sheet para definir tanto cabecera como datos
     const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Proyectos");
    XLSX.writeFile(workbook, "Proyectos.xlsx");
  };

  const sortedProyectos = useMemo(() => {
    // ORDENACION
    let sortedData = [...proyectos];
    if (sortConfig.key2) {
      console.log("Ordenandooooo",sortedData)
      sortedData.sort((a, b) => {
        let aValue, bValue;
            // Comparar subpropiedades
            if (sortConfig.key1 !== "") {
              if (sortConfig.key3 !== null) {
                aValue = a[sortConfig.key1][sortConfig.key2][sortConfig.key3];
                bValue = b[sortConfig.key1][sortConfig.key2][sortConfig.key3];
              }else{
                aValue = a[sortConfig.key1][sortConfig.key2];
                bValue = b[sortConfig.key1][sortConfig.key2];
              }
            } 
            // Comparar otros valores
            else if (sortConfig.key2 === "createdAt" || sortConfig.key2 === "updatedAt") {
              aValue = a[sortConfig.key2] ? new Date(a[sortConfig.key2]) : null;
              bValue = b[sortConfig.key2] ? new Date(b[sortConfig.key2]) : null;
            }else{
                aValue = a[sortConfig.key2];
                bValue = b[sortConfig.key2];
            }
            // Manejar valores nulos para que vayan al final
            if (aValue === null || aValue === undefined || Number.isNaN(aValue)) return 1;
            if (bValue === null ||  bValue === undefined || Number.isNaN(bValue)) return -1;
    
            // Comparar valores no nulos
            if (aValue < bValue) {
                return sortConfig.direction === 'asc' ? -1 : 1;
            }
            if (aValue > bValue) {
                return sortConfig.direction === 'asc' ? 1 : -1;
            }
            return 0;
      });
    }
  
    return sortedData;
  }, [proyectos, sortConfig]);
  
  
  const sortedFilteredProyectos = useMemo(() => {
     let buscarPorNum = searchTerm.numVisado!==""
    // FILTRADO
    let filteredList = sortedProyectos.filter(proy => {
      console.log("proy",proy,searchTerm)

     let coincideNumVisado = proy.num_visado && `${proy.num_visado}`.includes(searchTerm.numVisado.toLowerCase()) 
     let coincideNumVisPrevio = proy.proyectoPrevio?.num_visado? `${proy.proyectoPrevio.num_visado}`.includes(searchTerm.numVisado.toLowerCase())
                                :proy.num_visado_ante&& `${proy.num_visado_ante}`.includes(searchTerm.numVisado.toLowerCase()) 
      
     const matchesSearch = buscarPorNum? coincideNumVisado || coincideNumVisPrevio : true;
        const matchesEstado = searchTerm.estado==="activos"? proy.estado!=="anulado"? true : false 
                                                  :`${proy.estado}`.includes(searchTerm.estado.toLowerCase()) 
        const matchesTipoProyecto = searchTerm.tipoProyectoId!==""? `${proy.id_tipo_proyecto}` === `${searchTerm.tipoProyectoId}`: true
        const matchesProceso = searchTerm.proceso==="Sin"? (!`${proy.observacion}`.includes('Resellado') &&
        !`${proy.observacion}`.includes('Transferencia') &&
        !`${proy.observacion}`.includes('Pase'))
        
        :searchTerm.proceso!==""? `${proy.observacion}`.includes(searchTerm.proceso): true
        let matchesFiltro=true;
      if(searchTerm.filtroSelected!==""){
        if(searchTerm.filtroSelected === "proyectista")
        { 
          matchesFiltro = proy.proyectista 
          ? `${apellidosNombreFullPersona(proy.proyectista.persona, "ci")}`.toLowerCase().includes(searchTerm.textoFiltro.toLowerCase()) 
          : false;}
        else if(searchTerm.filtroSelected === "inmueble")
          { matchesFiltro = proy.inmueble 
            ? `${`${proy.inmueble.zona.nombre} ${proy.inmueble.cod_catastral}`}`.toLowerCase().includes(searchTerm.textoFiltro.toLowerCase()) 
            : false;}
        else if(searchTerm.filtroSelected === "propietario")
          { matchesFiltro = proy.propietarios // Asegúrate de que `propietarios` sea la lista de propietarios
            ? proy.propietarios.some((propietario) => 
                `${apellidosNombreFullPersona(propietario, "ci")}`.toLowerCase().includes(searchTerm.textoFiltro.toLowerCase())
              )
            : false;
        }
      }
        // Validar rango de fechas
      const matchesDate = 
          (!filters.fechaCreacion || (moment(filters.fechaCreacion).isValid() && moment(proy.createdAt).startOf('day').isSame(moment(filters.fechaCreacion).startOf('day')))) 
      const matchesDateEdicion = 
      (!filters.fechaEdicion || (moment(filters.fechaEdicion).isValid() && moment(proy.updatedAt).startOf('day').isSame(moment(filters.fechaEdicion).startOf('day'))))    
          // &&
          // (!endDate || (moment(endDate).isValid() && moment(recibo.fecha).startOf('day').isSameOrBefore(moment(endDate).startOf('day'))));

      // const matchesState = selectedEstados?.length > 0
      //   ? selectedSituaciones.some(situ => situ.value === proy.id_situacion)
      //   : true;

      return matchesSearch && matchesDateEdicion && matchesDate&& matchesFiltro && matchesEstado && matchesProceso && matchesTipoProyecto;
    });
    return filteredList;
}, [sortedProyectos, searchTerm.filtroSelected,filters.fechaCreacion,filters.fechaEdicion, searchTerm.textoFiltro, searchTerm.numVisado, searchTerm.estado, searchTerm.proceso, searchTerm.tipoProyectoId]);

  const handleShowPreview = () => {
    setPrintContent(getPreviewContent());
    setShowPreviewModal(true);
  };

  const handleClosePreview = () => {
    setShowPreviewModal(false);
  };

  const getPreviewContent = () => {
    const content = {
      head: [['N°','Cód. en el sistema','Núm. Visado','Proyectista','Inmueble','Propietario(s)','Tipo de Proy.','Carácter','Superficie (m2)', 'Honorarios (Bs.)', 'Visación (Bs.)', 'Monto pagado (Bs.)', 'Proc.', 'Obs.', 'Estado']],
      body: sortedFilteredProyectos.map((proyecto, index) => [
        index+1,
        `PRE${proyecto.id}`,
        proyecto.num_visado || "___",
        apellidosNombreFullPersona(proyecto.proyectista.persona, "ci"),
        `${proyecto.inmueble.zona.nombre} ${proyecto.inmueble.cod_catastral}`,
        `${proyecto.propietarios.map(prop=> `${apellidosNombreFullPersona(prop, "ci")}\n`)}`,
        proyecto.tipo?.nombre,
        proyecto.descripcion || 'N/A',
        formatNumber(proyecto.superficie_total),
        formatNumber(proyecto.honorarios_total),
        formatNumber(proyecto.comision_cat),
        formatNumber(proyecto.pagado_en_la_fecha),
        getNombreProcesoEspecialVisado(proyecto.observacion).clase,
        proyecto.comentario1 || "___",
        getEstadoProyecto(proyecto.estado).titulo,
      ])
    };
    return content;
  };

  const handleClearFilters = () => {
    setSearchTerm({
      numVisado: '',
      estado: 'activos',
      proceso: '',
      tipoProyectoId: '',
      textoFiltro: '',
      filtroSelected: ''
    });
    setFilters({
      fechaCreacion: '',
      fechaEdicion: ''
    });
    setSortConfig({ key1: "", key2: '', key3: null, direction: 'asc' });
  };


const filtros = ['proyectista','inmueble','propietario']
  return (
    <div className="list-container list5">
      <h1 className='table-title'>{rolActivo?.alias === "afiliado"? "Mis proyectos":"Lista de Proyectos"}</h1>
      <div className="list-content">
      
        <div className="list-container-header">
          <div className="left-buttons">
            <button title="Volver" onClick={handleBack} className="icon-button btn-transparent"><i className="bi bi-arrow-left"></i></button>
           
          </div>
          <div className="right-buttons">
          <button
                onClick={handleClearFilters}
                className="icon-button btn-transparent"
                title="Limpiar filtros"
                style={{marginRight:"1rem"}}
              >
                <i className="bi bi-x-circle"></i> {/* Ícono de escoba */}
              </button>
            <button title="Imprimir lista" onClick={handleShowPreview} className="icon-button btn-transparent" style={{marginRight:"0.3rem"}}><i className="bi bi-printer"></i></button>
            <button title="Exportar a excel (doble click)" onDoubleClick={handleExport} className="icon-button btn-transparent" style={{marginRight:"2rem"}}><i className="bi bi-file-spreadsheet"></i></button>
            <ActionButton onClick={handleCreate} outline icon='plus' floatEnd>
              Nuevo Proyecto
            </ActionButton>
          </div>
        </div>
        <div className='row list-controls'>
          <div className="sort-controls col-6 col-md-3 col-lg-2">
              <Form.Label>Filtro: </Form.Label>
             <Form.Control
                as="select"
                size='sm'
                className="form-control"
                value={searchTerm.filtroSelected}
                name='filtroSelected'
                onChange={handleSearch}
                style={{marginLeft:"7px"}}
                >
                <option value="" disabled>- Sin seleccionar -</option>
                {filtros?.map(filtro => (
                <option key={filtro} value={filtro}>{formatMayusculaCadaPalabra(filtro)}</option>  
              ))}

                </Form.Control>
                </div>
                <div className="sort-controls col-6 col-md-3 col-lg-2">
         
            <Form.Control
                size='sm'
                  type="text"
                  placeholder={`Filtrar por ${searchTerm.filtroSelected}`}
                  className="form-control"
                  value={searchTerm.textoFiltro}
                  name="textoFiltro"
                  onChange={handleSearch}
                />
          </div>
         
          <div className="sort-controls col-6  col-md-3 col-lg-2">
          <InputGroup size="sm">
        <InputGroup.Text>
        <i className="bi bi-search"></i>
        </InputGroup.Text>
        <Form.Control
             size='sm'
               type="text"
              placeholder="Buscar por N° visado"
              className="form-control"
              value={searchTerm.numVisado}
              name='numVisado'
              onChange={handleSearch}
            />
              </InputGroup>
          </div>
          <div className="sort-controls col-6 col-md-3 col-lg-2">
              <Form.Label>Tipo de proyecto: </Form.Label>
             <Form.Control
                as="select"
                size='sm'
                className="form-control"
                value={searchTerm.tipoProyectoId}
                name='tipoProyectoId'
                onChange={handleSearch}
                style={{marginLeft:"7px"}}
                >
                  <option value="">- Todos -</option>
                 {tiposProyectos?.map(tipo => (
                <option key={tipo.id} value={tipo.id}>{tipo.nombre}</option>  
              ))}
                
                </Form.Control>
                </div>
          <div className="sort-controls col-6 col-md-3 col-lg-2">
              <Form.Label>Proceso: </Form.Label>
             <Form.Control
                as="select"
                size='sm'
                className="form-control"
                value={searchTerm.proceso}
                name='proceso'
                onChange={handleSearch}
                style={{marginLeft:"7px"}}
                >
                  <option value="">- Todos -</option>
                {["Resellado","Transferencia","Pase","Sin"]?.map(estado => (
                <option key={estado} value={estado}>{getNombreProcesoEspecialVisado(estado).titulo}</option>  
              ))}
                
                </Form.Control>
                </div>
                <div className="sort-controls col-6 col-md-3 col-lg-2">
              <Form.Label>Estado: </Form.Label>
             <Form.Control
                as="select"
                size='sm'
                className="form-control"
                value={searchTerm.estado}
                name='estado'
                onChange={handleSearch}
                style={{marginLeft:"7px"}}
                >
                <option value="activos">- Activos -</option>
                {["visado","borrador","revision","aprobado","anulado"]?.map(estado => (
                <option key={estado} value={estado}>{getEstadoProyecto(estado).titulo}</option>  
              ))}

                <option value="">- Todos -</option>
                </Form.Control>
                </div>
                <div className="sort-controls col-6 col-md-3 col-lg-2">
            <Form.Label>Creado:</Form.Label>
                  <Form.Control
                type="date"
              value={filters.fechaCreacion}
              onChange={(e) => setFilters((prev=>({...prev, fechaCreacion:e.target.value})))}
              className="form-control"
              size='sm'
            />
          </div>
          <div className="sort-controls col-6 col-md-3 col-lg-2">
            <Form.Label>Últ. Mod.:</Form.Label>
                  <Form.Control
                type="date"
              value={filters.fechaEdicion}
              onChange={(e) => setFilters((prev=>({...prev, fechaEdicion:e.target.value})))}
              className="form-control"
              size='sm'
            />
          </div>
          <div className="sort-controls col-6 col-md-3 col-lg-2"></div>
          <div className="sort-controls col-6 col-md-3 col-lg-2"></div>
          <div className="sort-controls col-6 col-md-3 col-lg-2"></div>
          <div className="sort-controls col-6 col-md-3 col-lg-2"></div>
        </div>
        {proyectoState.statusGet === "loading"? (
            <p>Cargando proyectos...</p>
        ):proyectoState.statusGet === "failed"? (
            <p>- No se pudo obtener la lista de proyectos -</p>
        ):proyectos.length === 0 ? (
            <p>- No hay proyectos disponibles -</p>
          ) : (
          <div className='table-container'>
            <Table bordered hover size='sm'>
              <thead className='text-center align-middle'>
                <tr>
                  <th className='col-index'>#</th>
                  <th onClick={() => handleSort('','id')}>
                    Código
                    {sortConfig.key2 === 'id' && (
                        sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th onClick={() => handleSort('','num_visado')}>
                      Número Visado
                      {sortConfig.key2 === 'num_visado' && (
                        sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                      )}

                    </th>

                  <th onClick={() => handleSort('','createdAt')}>
                    Creado
                    {sortConfig.key2 === 'createdAt' && (
                        sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th onClick={() => handleSort('','updatedAt')}>
                    Última vez Modificado
                    {sortConfig.key2 === 'updatedAt' && (
                        sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                    <th onClick={(e) => handleSort('proyectista','persona','apellido1')}>
                      Proyectista
                      {sortConfig.key1 === 'proyectista' && (
                        sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                      )}
                      
                      {/* Botón invisible para enviar la palabra "proyectista" al hacer clic */}
                      {rolActivo?.alias!=="afiliado" && <button 
                        className='icon-button btn-secondary-outline'
                          title='Definir filtro por Proyectista'
                        onClick={(e) => {
                          e.stopPropagation(); // Evitar que el evento de clic se propague y dispare handleSort
                          handleSearch({target: {name: 'filtroSelected', value: "proyectista"}}); 
                        }} 
                        aria-hidden="true">
                        <i className="bi bi-funnel"></i>
                      </button>}
                    </th>

                  <th onClick={() => handleSort('inmueble','cod_catastral')}>
                    Inmueble
                    {sortConfig.key1 === 'inmueble' && (
                        sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                       <button 
                         title='Definir filtro por Inmueble'
                        className='icon-button btn-secondary-outline'
                        onClick={(e) => {
                          e.stopPropagation(); // Evitar que el evento de clic se propague y dispare handleSort
                          handleSearch({target: {name: 'filtroSelected', value: "inmueble"}}); 
                        }} 
                        aria-hidden="true">
                        <i className="bi bi-funnel"></i>
                      </button>
                  </th>
                  <th>
                    Propietario(s)
                       <button 
                        className='icon-button btn-secondary-outline'
                        title='Definir filtro por Propietario'
                        onClick={(e) => {
                          e.stopPropagation(); // Evitar que el evento de clic se propague y dispare handleSort
                          handleSearch({target: {name: 'filtroSelected', value: "propietario"}}); 
                        }} 
                        aria-hidden="true">
                        <i className="bi bi-funnel"></i>
                      </button>
                  </th>
                  <th onClick={() => handleSort('tipo', 'nombre')}>
                    Tipo de proyecto
                    {sortConfig.key1 === 'tipo' && (
                        sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th onClick={() => handleSort('','descripcion')}>
                    Carácter del proyecto
                    {sortConfig.key2 === 'descripcion' && (
                        sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th onClick={() => handleSort('','superficie_total')}>
                    Superficie (m2)
                    {sortConfig.key2 === 'superficie_total' && (
                        sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th onClick={() => handleSort('','honorarios_total')}>
                    Honorarios (Bs)
                    {sortConfig.key2 === 'honorarios_total' && (
                        sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th onClick={() => handleSort('','comision_cat')}>
                    Visación (Bs)
                    {sortConfig.key2 === 'comision_cat' && (
                        sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th>Monto Pagado (Bs)</th>
                  <th onClick={() => handleSort('','estado')}>
                    Estado
                    {sortConfig.key2 === 'estado' && (
                        sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th>Proceso Especial</th>
                  <th>Proyecto Previo</th>
                  <th onClick={() => handleSort('','comentario1')}>
                    Observación
                    {sortConfig.key2 === 'comentario1' && (
                        sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th className='col-simple-actions'>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {sortedFilteredProyectos.map((proyecto, index) => {
                const esDesplegado = proyectoDesplegado === proyecto.id;
                const esReselladoNormal = proyecto.observacion?.toLowerCase().includes("resellado") && !proyecto.observacion?.toLowerCase().includes("antecedente");
                  return(
                  <tr key={proyecto.id}>
                    <td>{index + 1}</td>
                    <td>{`PRE${proyecto.id}`}</td>
                    <td className='fw-bold'>{proyecto.num_visado || "_________"}</td>
                    <td>{fechaHoraBdAjustada(proyecto.createdAt).completa}</td>
                    <td>{fechaHoraBdAjustada(proyecto.updatedAt).completa}</td>
                    <td>{apellidosNombreFullPersona(proyecto.proyectista.persona, "ci")}</td>
                    <td>{`${proyecto.inmueble.zona.nombre} ${proyecto.inmueble.cod_catastral}`}</td>
                    <td>
                    {proyecto.propietarios?.length === 1 ? (
                      apellidosNombreFullPersona(proyecto.propietarios[0], "ci")
                    ) : proyecto.propietarios?.length > 0 &&(
                      <>
                      {!esDesplegado && (`${apellidosNombreFullPersona(proyecto.propietarios[0], "ci")} Y OTRO(S)`)}
                        <button
                          onClick={() => togglePropietarios(proyecto.id)}
                          type="button"
                          className="btn-sm btn-transparent"
                          title='Ver propietarios'
                          style={{
                            display: "inline-flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <span
                            style={{
                              display: "inline-block",
                              transform: esDesplegado ? "rotate(90deg)" : "rotate(0deg)",
                              transition: "transform 0.2s ease",
                            }}
                          >
                           <i className="bi bi-caret-right-fill"></i>
                          </span>
                        </button>
                      </>
                    )}
                    {esDesplegado && (
                      <ol>
                        {proyecto.propietarios.map((propietario) => (
                          <li key={propietario.id}>{apellidosNombreFullPersona(propietario, "ci")}</li>
                        ))}
                      </ol>
                    )}
                  </td>

                    <td>{proyecto.tipo.nombre}</td>
                    <td>{proyecto.descripcion}</td>
                    <td>{formatNumber(proyecto.superficie_total)}</td>
                    <td>{formatNumber(proyecto.honorarios_total)}</td>
                    <td>{formatNumber(proyecto.comision_cat)}</td>
                    <td>{formatNumber(proyecto.pagado_en_la_fecha)||  "_________"}</td>
                    <td><CustomBadge estado={proyecto.estado}/></td>
                    <td >{proyecto.observacion || "_________"}</td>
                    <td >{proyecto.proyectoPrevio?.num_visado || proyecto.num_visado_ante|| "_________"}</td>
                    <td >{proyecto.comentario1 || "_________"}</td>
                    <td>
                     {((proyecto.estado === "borrador") || (proyecto.estado ==="revision" && rolActivo?.alias === "gerente") || (proyecto.estado ==="aprobado" && rolActivo?.alias !=="afiliado")) 
                     && <button className="icon-button btn-secondary-outline" 
                            title="Editar" onClick={() => handleEdit(proyecto)}>
                        <i className="bi bi-pencil"></i>
                      </button>
                      }
                    { (proyecto.estado === "borrador" || ((proyecto.estado === "aprobado" ||proyecto.estado === "revision" )&& rolActivo?.alias === "gerente")) && !esReselladoNormal && 
                          <button className="icon-button btn-secondary-outline" 
                            title="Eliminar" onClick={() => handleDelete(proyecto.id)}>
                          <i className="bi bi-trash"></i>
                        </button>}
                       {/* {rolActivo?.alias === "gerente"&&proyecto.estado !== "visado" && proyecto.observacion?.toLowerCase().includes("resellado") && 
                        <button className="icon-button btn-secondary-outline" 
                            title="Cancelar proceso especial" onClick={() => handleCancelarProcesoEspecial(proyecto.id,"Resellado")}>
                            <i className="bi bi-backspace-reverse"></i>
                        </button>} */}
                        <button className="icon-button btn-secondary-outline" 
                            title="Ver" onClick={() => handleVer(proyecto)}>
                          <i className="bi bi-eye"></i>
                        </button>
          
                          <button className="icon-button btn-secondary-outline" 
                              title="Duplicar" onClick={() => handleDuplicar(proyecto)}>
                              <i className="bi bi-files"></i>
                          </button>
                    
                      {proyecto.estado === "visado"  && 
                      <>
                        <button className="icon-button btn-secondary-outline" 
                                title="Resellar" onClick={() => handleResellar(proyecto)}>
                          <i className="bi bi-ui-checks"></i>
                          </button>
                      {rolActivo?.alias==="gerente" && (!proyecto.superficie_aprobada || parseFloat(proyecto.superficie_aprobada)===0) && <>
                       <button className="icon-button btn-secondary-outline" 
                              title="Transferir propietario" onClick={() => handleTransferir(proyecto)}>
                          <i className="bi bi-box-arrow-right"></i>
                      </button></>}
                      <button className="icon-button btn-secondary-outline" 
                              title="Ver Recibo" onClick={() => handleViewRecibo(proyecto)}>
                          <i className="bi bi-receipt"></i>
                      </button>
                      </>}
                    </td>
                  </tr>
                )})}
              </tbody>
            </Table>
          </div>
        )}
        <FormProyecto handleViewRecibo={handleViewRecibo}
        currentProyecto={currentProyecto} 
        tipoProceso={showModal.tipo}
        showModal={showModal.open} 
        handleClose={handleClose} />
        <VerProyectoModal headerColor={headerColor} currentProyecto={currentProyecto} show={showModalVer} handleClose={handleCloseVer} />
       
        <CustomPrintModalH
          show={showPreviewModal}
          handleClose={handleClosePreview}
          title1="LISTA DE PROYECTOS"
          title2=""
          content={printContent}
          copia={false}
        />
              {reciboData && showModalRecibo&& (
                <ImprimirRecibo
                    show={showModalRecibo}
                    handleClose={() => setShowModalRecibo(false)}
                    recibo={reciboData.recibo}
                    reciboDetalles={reciboData.reciboDetalles}
                    totalLiteral={reciboData.totalLiteral}
                />
            )}
      </div>
    </div>
  );
};

export default ListProyectos;
