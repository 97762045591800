import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchRecibos, fetchReciboById } from '../../reducers/recibo_slice';
import Table from 'react-bootstrap/Table';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import CustomPrintModal from '../UI/CustomPrintModal2';
import ActionButton from '../UI/Buttons/ActionButton';
import { formaFechaI, formatMonetaryValue, numeroALetras } from '../../util/valueCalculator';
import ImprimirRecibo, { fechaHoraBdAjustada } from '../recibo/ImprimirRecibo';
import Modal from 'react-bootstrap/Modal';
import ReciboForm from './ReciboForm';
import { fetchAllCajasDiarias} from "../../reducers/caja_slice";
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';

const ReciboList = () => {
    const dispatch = useDispatch();
    const userId = useSelector(state => state.sesion.id);
    const { nombre, rolActivo, roles,id } = useSelector(state => state.sesion);
    const cajaState = useSelector(state => state.caja || { cajas: [] });
    const cajasDiarias = cajaState.cajasDiarias || [];
    const idCajaDiaria = useSelector(state => state.caja.idCajaDiaria);
    const reciboState = useSelector(state => state.recibo || { recibos: [] });
    const recibos = reciboState.recibos || [];

    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const [printContent, setPrintContent] = useState({ head: [], body: [] });
    const [reciboData, setReciboData] = useState(null);
    const [showPrintModal, setShowPrintModal] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [sortConfig, setSortConfig] = useState({ key: 'nroRecibo', direction: 'desc' });

    const [searchTerm, setSearchTerm] = useState('');
    const fecha = moment().tz("America/La_Paz").format('YYYY-MM-DD');
    const currentYear = new Date().getFullYear();
    const currentMonth = String(new Date().getMonth() + 1).padStart(2, '0');
    const currentDay = String(new Date().getDate()).padStart(2, '0');

    const [startDate, setStartDate] = useState(fecha);
    const [endDate, setEndDate] = useState(fecha);
    const navigate = useNavigate();
    const xgestion = currentYear;

    useEffect(() => {
        dispatch(fetchRecibos());
        dispatch(fetchAllCajasDiarias()); // Llamar al thunk para cajas diarias

    }, [dispatch,id]);

    const handleViewRecibo = async (id_recibo) => {
        try {
            const recibo = await dispatch(fetchReciboById(id_recibo)).unwrap();
            const totalLiteral = numeroALetras(recibo.monto);

            setReciboData({
                recibo,
                reciboDetalles: recibo.reciboDets,
                totalLiteral
            });
            setShowPrintModal(true);
        } catch (error) {
            Swal.fire('Error', 'No se pudo obtener el recibo', 'error');
        }
    };

    const requestSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const filteredRecibos = useMemo(() => {
        return recibos.filter(recibo => {
            // Validar campos de búsqueda
            const matchesSearch = [recibo.beneficiario, recibo.concepto].some(field =>
                (field?.toLowerCase() || "").includes(searchTerm?.toLowerCase() || "")
            );
    
            // Validar rango de fechas
            const matchesDateRange = 
                (!startDate || (moment(startDate).isValid() && moment(recibo.fecha).startOf('day').isSameOrAfter(moment(startDate).startOf('day')))) &&
                (!endDate || (moment(endDate).isValid() && moment(recibo.fecha).startOf('day').isSameOrBefore(moment(endDate).startOf('day'))));
    
            // Validar nroRecibo
            const matchesNroRecibo = typeof recibo.nroRecibo === 'string' && recibo.nroRecibo.startsWith(`I${xgestion}`);
    
            return matchesSearch && matchesDateRange && matchesNroRecibo;
        });
    }, [recibos, searchTerm, startDate, endDate, xgestion]);
    
    const totalRecibos = useMemo(() => {
        return filteredRecibos.reduce((total, recibo) => total + parseFloat(recibo.monto || 0), 0);
    }, [filteredRecibos]);

    const sortedRecibos = useMemo(() => {
        let sortedData = [...filteredRecibos];
        if (sortConfig.key) {
            sortedData.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'asc' ? -1 : 1;
                if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'asc' ? 1 : -1;
                return 0;
            });
        }
        return sortedData;
    }, [filteredRecibos, sortConfig]);

    const handleExport = () => {
        const worksheet = XLSX.utils.json_to_sheet(sortedRecibos.map(recibo => ({
            Recibo: recibo.nroRecibo,
            Pago: recibo.pago,
            Fecha: formaFechaI(recibo.fecha),
            Beneficiario: recibo.beneficiario,
            Concepto: recibo.concepto,
            Monto: formatMonetaryValue(recibo.monto),
        })));
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Recibos");
        XLSX.writeFile(workbook, "recibos.xlsx");
    };
    const handleBack = () => {
        navigate('/admin'); // Ajusta la ruta según corresponda
      };
    const handleShowPreview = () => {
        const content = {
            head: [['Recibo', 'Tipo','Fecha', 'Beneficiario', 'Concepto', 'Monto']],
            body: sortedRecibos.map(recibo => [
                recibo.nroRecibo,
                recibo.pago,
                formaFechaI(recibo.fecha),
                recibo.beneficiario,
                recibo.concepto,
                formatMonetaryValue(recibo.monto)
            ])
        };
        setPrintContent(content);
        setShowPreviewModal(true);
    };

    const handleCreate = () => {
        setShowModal(true);
    };

    const handleCloseModal = async (id_recibo) => {
        console.log("idRecibo en List=",id_recibo)
        setShowModal(false);
        if (id_recibo) {
            await dispatch(fetchRecibos()).unwrap();
            await handleViewRecibo(id_recibo);
        }
    };

    return (
        <div className="list-container list1">
            <h1 className='table-title'>Gestión de Recibos de Ingreso</h1>
            <div className="list-controls">
            <ActionButton 
                onClick={handleCreate} 
                outline 
                icon='plus' 
                disabled={!idCajaDiaria} // Deshabilitar si no hay caja abierta
            >
                Nuevo Registro de Ingresos
            </ActionButton>

                <div className="filters">
                    <input
                        type="text"
                        placeholder="Buscar por Beneficiario o Concepto"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <input
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                    />
                    <input
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                    />
                </div>
                <div className="right-buttons">
                    <button title="Volver" onClick={handleBack} className="icon-button btn-transparent"><i className="bi bi-arrow-left"></i></button>
                    <button title="Imprimir" onClick={handleShowPreview} className="icon-button btn-transparent">
                        <i className="bi bi-printer"></i>
                    </button>
                    <button title="Exportar a excel" onDoubleClick={handleExport} className="icon-button btn-transparent">
                        <i className="bi bi-file-spreadsheet"></i>
                    </button>
                </div>
            </div>
            {recibos.length === 0 ? (
                <p>No hay recibos disponibles.</p>
            ) : (
                <div style={{ maxHeight: '600px', overflowY: 'auto' }}>
                    <Table bordered hover size='sm'>
                        <thead>
                            <tr>
                                <th></th>
                                <th onClick={() => requestSort('nroRecibo')}>Recibo</th>
                                <th onClick={() => requestSort('pago')}>Tipo</th>
                                <th style={{ width: '100px' }} onClick={() => requestSort('fecha')}>Fecha</th>
                                <th style={{ width: '100px' }} onClick={() => requestSort('fecha')}>Hora</th>
                                <th onClick={() => requestSort('usuario')}>Usuario</th>
                                <th onClick={() => requestSort('beneficiario')}>Beneficiario</th>
                                <th onClick={() => requestSort('concepto')}>Concepto</th>
                                <th onClick={() => requestSort('monto')}>Monto</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortedRecibos.map((recibo, index) => (
                                <tr key={recibo.id}>
                                    <td>{index+1}</td>
                                    <td>{recibo.nroRecibo}</td>
                                    <td>{recibo.pago}</td>
                                    <td>{fechaHoraBdAjustada(recibo.fecha).formattedDate}</td>
                                    <td>{fechaHoraBdAjustada(recibo.fecha).horaMinutos}</td>
                                    <td>{recibo.usuario.usuario}</td>
                                    <td>{recibo.beneficiario}</td>
                                    <td>{recibo.concepto}</td>
                                    <td>{formatMonetaryValue(recibo.monto)}</td>
                                    <td>
                                        <button className="icon-button btn-secondary-outline" title="Ver Recibo" onClick={() => handleViewRecibo(recibo.id)}>
                                            <i className="bi bi-receipt"></i>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            )}

            <Modal show={showModal} onHide={handleCloseModal} size="xl" backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Nuevo Registro de Ingresos</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ReciboForm handleClose={handleCloseModal} />
                </Modal.Body>
            </Modal>

            {reciboData && (
                <ImprimirRecibo
                    show={showPrintModal}
                    handleClose={() => setShowPrintModal(false)}
                    recibo={reciboData.recibo}
                    reciboDetalles={reciboData.reciboDetalles}
                    totalLiteral={reciboData.totalLiteral}
                />
            )}

            <CustomPrintModal
                show={showPreviewModal}
                handleClose={() => setShowPreviewModal(false)}
                title1="REPORTE DE INGRESOS"
                title2={`DEL ${startDate} AL ${endDate}`} // Rango de fechas
                title3={searchTerm ? `FILTRO DE BÚSQUEDA=${searchTerm}` : ''}
                title4=""
                title5=""
                total1={`MONTO TOTAL: ${formatMonetaryValue(totalRecibos)}`} // Total calculado
                total2=""
                content={printContent}
                copia={false}
            />
        </div>
    );
};

export default ReciboList;
