import { Link, Outlet } from "react-router-dom";
import logo from '../assets/logo.png';
import portadaImg from '../assets/portada.png';
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import { getUploadUrl } from "../util/valueCalculator";

const AutenticacionPage = () => {
  const portadas = useSelector(state => state.portada.portadas || []);
  
      
  const portadaVigente = portadas.find(portada => portada.url === "VIGENTE");
  let portada = portadaImg; // Valor predeterminado

  // Si hay una portada vigente, usar su URL como fondo
  if (portadaVigente && portadaVigente.foto) {
    portada = getUploadUrl(portadaVigente.foto);
  }

    return ( 
        <div className="auth-container">
            <div className="auth-form-container img-background" style={{ backgroundImage:  `url(${portada})`}}>
            <div className="overlay"></div> 
            <Link to="/" className="brand-link auth-title d-flex align-items-center">
            <img
              alt="Logo CAT"
              src={logo}
              width="80"
              height="70"
              className="d-inline-block align-top"
            />
            <span className="brand-text">Sistema CAT</span>
          </Link>   
          <Outlet/>
           <ToastContainer />
        </div>
        </div>
       )
}
export default AutenticacionPage;