import React, { useState, useEffect } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import logo from '../../assets/logo.png';
import { Link } from 'react-router-dom';
import '../../styles/Header.scss';

const PublicHeader = () => {
  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header className={`public-header ${scrolled ? 'scrolled' : ''}`}>
      <Navbar expand="lg" variant='dark'>
        <Container>
        <Navbar.Brand>
          <Link to="/" className="brand-link d-flex align-items-center">
            <img
              alt="Logo CIAB"
              src={logo}
              width="80"
              height="70"
              className="d-inline-block align-top"
            />
            <span className="brand-text">CAT</span>
          </Link>
        </Navbar.Brand>
          <Navbar.Toggle aria-controls="public-nav"/>
          <Navbar.Collapse id="public-nav">
            <Nav className="ms-auto">
              <Link to="/login" className="button-mine btn-mine-primary">
                Ingresar al sistema
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default PublicHeader;