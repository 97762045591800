import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addPropietario, modifyPropietario, fetchPropietarios } from '../../reducers/propietario_slice';
import { fetchAfiliados } from '../../reducers/afiliado_slice';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';
import { Alert, Col, Row } from 'react-bootstrap';
import ActionButton from '../UI/Buttons/ActionButton';
import AfiliadoList from './AfiliadoList';
import { fetchSituaciones } from '../../reducers/situacion_slice';
import { nombreApellidosFullPersona } from '../../util/customFormatter';

const initialState = {
    id_afiliado:"",
    afiliado:null
}
const SelectAfiliado = ({ onSelect, afiliado, labelText, permitirEdicion = true }) => {
  const dispatch = useDispatch();
  const [datos, setDatos] = useState(initialState);
  const [showModal, setShowModal] = useState(false);
  const situaciones = useSelector((state) => state.situacion.situaciones);
const { rolActivo } = useSelector(state => state.sesion);
  const handleSelectAfiliado = (afiliado) => {
    if (permitirEdicion) {
      const afiliadoId = afiliado ? afiliado.id : '';
      setDatos({ ...datos, id_afiliado: afiliadoId, afiliadoSeleccionado: afiliado });
      onSelect(labelText.toLowerCase(), afiliado);
      setShowModal(false); // Cierra el modal después de seleccionar
    }
  };

  useEffect(() => {
    dispatch(fetchAfiliados());
    dispatch(fetchSituaciones());
  }, [dispatch]);

  useEffect(() => {
    if (afiliado) {
      setDatos({ ...datos, id_afiliado: afiliado.id, afiliadoSeleccionado: afiliado });
    } else {
      setDatos(initialState);
    }
  }, [afiliado]);

  const selectedSituaciones = situaciones
    .filter((s) => s.alias === 'activo' || s.alias === 'activo2' || s.alias === 'externo'|| s.alias === 'trasladoloc')
    .map((s) => ({ label: s.nombre, value: s.id }));

  return (
    <>
      <Row xs={1} className="mt-4">
        <Col className="col-form">
          <Form.Label>{labelText}</Form.Label>
          {datos.afiliadoSeleccionado ? (
            <div className="selected-container p-3 border rounded shadow-sm">
              <div>
                <strong>{`${nombreApellidosFullPersona(datos.afiliadoSeleccionado.persona)}`}</strong>
                <br />
                <span>C.I.: {datos.afiliadoSeleccionado.persona?.ci}</span>
                <br />
                <span>{datos.afiliadoSeleccionado.situacion?.nombre}</span>
              </div>
              {permitirEdicion && (
                <Button
                  variant="link"
                  onClick={() => {
                    setDatos({ ...datos, id_afiliado: '', afiliadoSeleccionado: null });
                    handleSelectAfiliado(null);
                    setShowModal(true);
                  }}
                >
                  Cambiar afiliado
                </Button>
              )}
            </div>
          ) : (
            <div className="selected-container p-3 border rounded shadow-sm ">
                <p>No se ha seleccionado un afiliado aún.</p>
             
           {rolActivo?.alias !=="afiliado" &&  
            <Button variant="link" 
                    onClick={() => setShowModal(true)}>
              Seleccionar afiliado
            </Button>}
            </div>
          )}
        </Col>
      </Row>

      {/* Modal para seleccionar afiliados */}
      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Seleccionar afiliado</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AfiliadoList
            onSelectAfiliado={handleSelectAfiliado}
            selectedSituaciones={selectedSituaciones}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SelectAfiliado;
