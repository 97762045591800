import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';
import { fetchLugares } from '../../reducers/lugar_slice';
import { fetchAfiliados, fetchInscripcionesAfiliados, inscribirAfiliado, modifySituacionAfiliado } from '../../reducers/afiliado_slice';
import { fetchRegiones, fetchRegionesByPais, fetchRegionesPorPais } from '../../reducers/region_slice';
import { fechaHoraBdAjustada } from '../recibo/ImprimirRecibo';
import { nombreApellidosFullPersona } from '../../util/customFormatter';

const initialState = {
    id_afiliado:"",
    num_reg: "",
    fecha_reg:"",
    id_region_reg_cab:"",
    id_lugar_residencia:"",
    situacion:"activo",
    comentario:"Inscripción completada",
  }

  const initialFormDetalles = {title:"Completar inscripción",
    headerColor:"#2f69b4", 
    buttonText:"Completar" }
  
const InscripcionModal = ({ currentAfiliado, showModal, handleClose }) => {
    const dispatch = useDispatch();
  const [datos, setDatos] = useState(initialState);
  const [error, setError] = useState('');
  const [isModified, setIsModified] = useState(false);
  const [formDetalles, setFormDetalles] = useState(initialFormDetalles);
  const lugares = useSelector(state => state.lugar.lugares) || [];
  const regiones = useSelector(state => state.region.regiones) || [];


  useEffect(()=>{
    dispatch(fetchLugares());
    dispatch(fetchRegionesPorPais("BOLIVIA"));
  },[dispatch])


  useEffect(() => {
    if(showModal)
    { resetForm();
    }
  }, [showModal]);


  useEffect(() => {
    if(currentAfiliado?.situacion)
    { 
      const situAlias = currentAfiliado?.situacion.alias === "preinscrito2"? "activo" : "externo"
      setDatos(prev => ({...prev, situacion:situAlias}))
    }
  }, [currentAfiliado?.situacion?.alias]);

  const handleSubmit = e => {
    e.preventDefault();
    const {fecha_reg, ...afiliadoData} = {
      ...datos,
      id_afiliado: currentAfiliado?.id,
      fecha_reg_cab: datos.fecha_reg,
      fecha_reg_cat: datos.fecha_reg,
      id_lugar_residencia:tarijaLugar.id
    };
    setError('');
console.log("datos",datos)
const regionCab=regiones.find(l=>`${l.id}`===datos.id_region_reg_cab) || "";
  
    let mensaje=` <div>Nuevo afiliado: <strong> Arq. ${nombreApellidosFullPersona(currentAfiliado?.persona,"ci")} </strong></div>
    <br>
      <ul style="list-style: none; padding: 0">
      <li>N° Registro: <strong>${afiliadoData.num_reg}</strong></li>
      <li>Fecha Registro: <strong>${fechaHoraBdAjustada(afiliadoData.fecha_reg_cab).formattedDate}</strong></li>
      <li>Lugar Registro CAB: <strong>${regionCab?.nombre}</strong></li>
      </ul>
     `
   
    Swal.fire({
        title: 'Confirmar inscripción',
        html: mensaje,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, continuar',
        cancelButtonText: 'No, cancelar',
        reverseButtons: true
    }).then(async (result) => {
        if (result.isConfirmed) {
          dispatch(modifySituacionAfiliado({ id: currentAfiliado.id, afiliado: afiliadoData })).then(response => {
            if (response.error || response.payload.error) {
              let errorMensaje =null
              if(response.payload.error === "Validation error"){
                errorMensaje="El número de registro ya está asignado a otro afiliado."
              }
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: errorMensaje || response.payload.error || response.error.message || 'Error al actualizar la situacion',
              });
            } else {
              handleCloseForm()
              dispatch(fetchInscripcionesAfiliados());
            }
          }).catch(error=> Swal.fire('Error', 'No se pudo registrar el nuevo afiliado', 'error'))

        }
    });
  
   
  };

  const cambiarSituacionAfiliado = () => {
    const id = currentAfiliado.id
    const data = {
      situacion:"preinscrito",
      comentario:"Respuesta negativa del C.A.B."
    }
    dispatch(modifySituacionAfiliado({id, afiliado:data}))
    .then(data => dispatch(fetchInscripcionesAfiliados()))
    handleClose();
};
  const resetForm = () => {
    setDatos(initialState);
    setFormDetalles(initialFormDetalles);
    setIsModified(false);
  };

  const handleCloseForm = () =>{
    handleClose();
  }
  const handleChange = e => {
    const { name, value } = e.target;
    setDatos((prevDatos) => ({ ...prevDatos, 
      [name]: value
    }));
    setIsModified(true);
  };

  const tarijaLugar=lugares.find(l=>l.nombre==="TARIJA") || "";
    return (
        <Modal show={showModal} onHide={handleCloseForm}>
        <Modal.Header closeButton style={{ backgroundColor: formDetalles.headerColor, color: 'white' }}>
          <Modal.Title>{formDetalles.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit} className='normal-form'>
            <Form.Group className='row'>
            <div className='col-12'>
            <Form.Label>Número de registro</Form.Label>
            <Form.Control
              type="text"
              placeholder="N° de registro"
              name="num_reg"
              value={datos.num_reg}
              onChange={handleChange}
              required
            />
            </div>
            <div className='col-12 mt-3'>
            <Form.Label>Fecha de registro</Form.Label>
                    <Form.Control
                    type="date"
                    name="fecha_reg"
                    value={datos.fecha_reg}
                    onChange={handleChange}
                    required
                            />
                          </div>
                      <div className='col-12 mt-3'>
                            <Form.Label>Lugar de registro C.A.B. (Nacional)</Form.Label>
                            <Form.Control
                                as="select"
                                name="id_region_reg_cab"
                                value={datos.id_region_reg_cab}
                                onChange={handleChange}
                                required
                            >
                                <option value="">Selecciona un lugar</option>
                                {regiones.map(region =>
                                 (<option key={region.id} value={region.id}>{`${region.nombre}`}</option>
                                ))}
                            </Form.Control>
                            </div>
                            <div className='col-12 mt-3'>
                            <Form.Label>Lugar de residencia</Form.Label>
                            <Form.Control
                                type="text"
                                name="id_lugar_residencia"
                                value={tarijaLugar.nombre}
                                disabled
                            />
                               
                            </div>

              </Form.Group>
          
              {error && <p className="text-danger">{error}</p>}
          <div className="d-flex justify-content-end mt-5">
            <button className="button-mine btn-cancel-outline" type="button" onClick={handleCloseForm}>
              Cancelar
            </button>
        {currentAfiliado?.situacion?.alias === "preinscrito2" && <button className="button-mine btn-light-blue-outline" type="button" onClick={cambiarSituacionAfiliado}>
            <i className="bi bi-arrow-left"></i> Retroceder inscripción
            </button>}
            <button className="button-mine btn-success-outline" type="submit" disabled={currentAfiliado && !isModified}>
            <i className="bi bi-check-circle"></i> {formDetalles.buttonText}
            </button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
    );
};

export default InscripcionModal;
