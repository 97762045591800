import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllDepositos } from '../../reducers/deposito_slice';
import { fetchPersonas } from '../../reducers/persona_slice';
import { Table, Button, Spinner } from 'react-bootstrap';
import { BsEye, BsPrinter, BsFileEarmarkSpreadsheet } from 'react-icons/bs';
import DepositoForm from './DepositoForm';
import * as XLSX from 'xlsx';
import CustomPrintModal from '../UI/CustomPrintModal';
import { formaFechaI, formatMonetaryValue, numeroALetras } from '../../util/valueCalculator';
import moment from 'moment-timezone';

const DepositoList = () => {
  const dispatch = useDispatch();
  const { depositos, statusGet, error } = useSelector(state => state.deposito);
  const personas = useSelector(state => state.persona.personas);

  const [showModal, setShowModal] = useState(false);
  const [selectedDeposito, setSelectedDeposito] = useState(null);
  const fecha = moment().tz("America/La_Paz").format('YYYY-MM-DD');
  const currentYear = new Date().getFullYear();
  const currentMonth = String(new Date().getMonth() + 1).padStart(2, '0');
  const currentDay = String(new Date().getDate()).padStart(2, '0');

  const [startDate, setStartDate] = useState(fecha);
  const [endDate, setEndDate] = useState(fecha);
  const [showPrintModal, setShowPrintModal] = useState(false);
  const [printContent, setPrintContent] = useState({ head: [], body: [] });

  useEffect(() => {
    dispatch(fetchAllDepositos());
    dispatch(fetchPersonas());
  }, [dispatch]);

  const depositosConPersonas = depositos.map(deposito => {
    const persona = personas.find(p => p.ci === deposito.ci);
    return {
      ...deposito,
      persona: persona ? `${persona.nombre} ${persona.apellido1 || ""} ${persona.apellido2 || ""}` : 'No registrado',
    };
  });



  const filteredDepositos = useMemo(() => {
      return depositosConPersonas.filter(deposito => {
          // Convertir la fecha del depósito a un objeto Moment.js
          const depositDate = moment(deposito.fecha).startOf('day');
  
          // Verificar si la fecha del depósito es igual o posterior a startDate
          const isAfterStart = !startDate || (moment(startDate).isValid() && depositDate.isSameOrAfter(moment(startDate).startOf('day')));
  
          // Verificar si la fecha del depósito es igual o anterior a endDate
          const isBeforeEnd = !endDate || (moment(endDate).isValid() && depositDate.isSameOrBefore(moment(endDate).startOf('day')));
  
          // Retornar true si ambas condiciones son verdaderas
          return isAfterStart && isBeforeEnd;
      });
  }, [depositosConPersonas, startDate, endDate]);



  const handleOpenModal = (deposito) => {
    setSelectedDeposito(deposito);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setSelectedDeposito(null);
    setShowModal(false);
    dispatch(fetchAllDepositos()); // Recargar los depósitos
  };

  const handleExportExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(
      filteredDepositos.map((dep, index) => ({
        Número: index + 1,
        Fecha: dep.fecha,
        CI: dep.ci,
        "Nombre Completo": dep.persona,
        "Monto Total": parseFloat(dep.monto_total).toFixed(2),
        Usuario: dep.id_usuario,
      }))
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Depósitos");
    XLSX.writeFile(workbook, "depositos.xlsx");
  };

  const handleShowPrint = () => {
    const content = {
      head: [['#', 'Fecha', 'CI', 'Nombre Completo', 'Monto Total', 'Usuario']],
      body: filteredDepositos.map((dep, index) => [
        index + 1,
        dep.fecha,
        dep.ci,
        dep.persona,
        parseFloat(dep.monto_total).toFixed(2),
        dep.id_usuario,
      ]),
    };
    setPrintContent(content);
    setShowPrintModal(true);
  };

  const handleClosePrintModal = () => setShowPrintModal(false);

  if (statusGet === 'loading') {
    return (
      <div className="text-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Cargando...</span>
        </Spinner>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center text-danger">
        <p>Ocurrió un error al cargar los depósitos: {error}</p>
      </div>
    );
  }

  return (
    <div className="list-container list0">
      <h2 className="text-center">Lista de Depósitos</h2>

      <div className="filter-section d-flex justify-content-between mb-3">
        <div>
          <label>Fecha Inicio:</label>
          <input
            type="date"
            value={startDate}
            onChange={e => setStartDate(e.target.value)}
          />
          <label className="ms-3">Fecha Final:</label>
          <input
            type="date"
            value={endDate}
            onChange={e => setEndDate(e.target.value)}
          />
        </div>
        <div>
          <Button variant="outline-secondary" onClick={handleShowPrint}>
            <BsPrinter /> Imprimir
          </Button>
          <Button variant="outline-success" onDoubleClick={handleExportExcel} className="ms-2">
            <BsFileEarmarkSpreadsheet /> Exportar a Excel
          </Button>
        </div>
      </div>

      <div className="table-container">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Fecha</th>
              <th>CI</th>
              <th>Nombre Completo</th>
              <th>Monto Total</th>
              <th>Usuario</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {filteredDepositos.map((deposito, index) => (
              <tr key={deposito.id}>
                <td>{index + 1}</td>
                <td>{formaFechaI(deposito.fecha)}</td>
                <td>{deposito.ci}</td>
                <td>{deposito.persona}</td>
                <td>{parseFloat(deposito.monto_total).toFixed(2)} Bs.</td>
                <td>{deposito.id_usuario}</td>
                <td>
                  <Button variant="info" onClick={() => handleOpenModal(deposito)}>
                    <BsEye /> Ver Detalles
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      {showModal && (
        <DepositoForm
          show={showModal}
          onHide={handleCloseModal}
          deposito={selectedDeposito}
        />
      )}

      <CustomPrintModal
        show={showPrintModal}
        handleClose={handleClosePrintModal}
        title1="LISTA DE DEPÓSITOS"
        content={printContent}
        title2=""
        copia={false}
      />
    </div>
  );
};

export default DepositoList;
