import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login } from "../../actions/authAction";
import '../../styles/Auth.scss'
import { Alert, Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const LoginForm = () => {
    const [formData, setFormData] = useState({ usuario: '', clave_acceso: '' });
    const [isSending, setIsSending] = useState(false);
    const [error, setError] = useState(null);
const [showPassword, setShowPassword] = useState(false); 
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleSubmitLogin = (e) => {
        e.preventDefault();

        setIsSending(true)

        dispatch(login(formData)).then((response) => {
          const errorMensaje=typeof response.message === 'string'? response.message : "Error en el servidor, vuelva a intentarlo más tarde.";
            if(response.status === 200){
              navigate('/admin')
            }else if(response.status === 400){
              setError(errorMensaje)
            }else if(response.status === 500){
              toast.error(errorMensaje)
            }
            setIsSending(false)
           })
        };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
      };
        // Función para alternar la visibilidad de la contraseña al presionar y soltar el botón
        const handleMouseDown = () => {
          setShowPassword(true);  // Mostrar la contraseña al presionar el botón
      };
  
      const handleMouseUp = () => {
          setShowPassword(false);  // Ocultar la contraseña cuando se suelta el botón
      };

    return (<>
    <Form onSubmit={handleSubmitLogin} className="form-auth">
      {error && <Alert variant="danger">
         {error} 
      </Alert>
}
      <div className="mb-3">
        <label htmlFor="usuario" className="form-label">
          Usuario
        </label>
        <input
          type="text"
          className="form-control"
          placeholder="Usuario"
          name="usuario"
          value={formData.usuario}
          onChange={handleChange}
          required
        />
      </div>
      <div className="mb-3">
        <label htmlFor="clave_acceso" className="form-label">
        Clave de acceso
        </label>
        <div className="password-container" style={{ position: 'relative' }}>
                    <Form.Control
                        type={showPassword ? 'text' : 'password'} // Alternar entre texto y contraseña
                        name="clave_acceso"
                        placeholder="Clave de acceso"
                        value={formData.clave_acceso}
                        onChange={handleChange}
                        disabled={isSending}
                    />
                    <Button
                        variant="link"
                        style={{
                            position: 'absolute',
                            top: '50%',
                            right: '10px',
                            transform: 'translateY(-50%)',
                            color:"gray"
                        }}
                        onMouseDown={handleMouseDown} // Muestra la contraseña al presionar el botón
                        onMouseUp={handleMouseUp}     // Oculta la contraseña cuando se suelta el botón
                        onMouseLeave={handleMouseUp}
                        onTouchStart={handleMouseDown} 
                        onTouchEnd={handleMouseUp}  
                       >
                        {showPassword ? <FaEyeSlash /> : <FaEye />} {/* Icono de ojo */}
                    </Button>
                </div>
      </div>
      <div className="button-container">
      <button disabled={isSending} className="button-mine btn-mine-primary  w-50">
        {isSending ? "Iniciando..." : "Iniciar sesión"}
      </button>
      </div>
    </Form>
    </>)
}
export default LoginForm;